//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { PlanModel } from '@/modules/plan/plan-model';
import { i18n, getLanguageCode } from '@/i18n';
import { routerAsync } from '@/app-module';

const { fields } = PlanModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.description,
  fields.type,
  fields.numberOfDays,
  fields.programs,
]);

export default {
  name: 'app-plan-reorder-page',

  props: ['id'],

  async created() {
    await this.doFind(this.id);
    this.record.numberOfDays = `${this.record.numberOfDays}`
    this.model = formSchema.initialValues(this.record);

    for (let index = 0; index < this.model.programs.length; index++) {
      this.programList[index+1] = this.model.programs[index].places;
    }
  },

  data() {
    return {
      time: 0,
      drag: false,
      model: null,
      language: getLanguageCode(),
      visibleImageDialog: false,
      visibleTimeDialog: false,
      selectedPlaceID: null,
      selectedProgramKey: null,
      selectedPlaceIndex: null,
      placeImages: [],
      programList: {},
      places: [],
      // images: [],
    };
  },

  computed: {
    ...mapGetters({
      record: 'plan/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'plan/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    isEditing() {
      return !!this.id;
    },
    selectImageDimensions() {
      if (this.is_screen_xs) {
        return '50px'
      } else {
        return '105px'
      }
    },
    images() {
      let selectedImgs = []
      for (const property in this.programList) {
        this.programList[property].forEach(place => {
          const img = place.place.placePictures.filter(img => img.publicUrl == place.selectedImage)
          if (img.length) {
            selectedImgs.push({
              id: img[0].id,
              name: img[0].name,
              privateUrl: img[0].privateUrl,
              publicUrl: img[0].publicUrl,
            })
          }
        });
      }
      return selectedImgs
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  // async created() {
  //   // await this.doFind(this.id);
  //   // this.model = formSchema.initialValues(this.record);
  //   // this.center.lat = parseFloat(this.model.lat);
  //   // this.center.lng = parseFloat(this.model.lng);
  //   this.model = {
  //     id: '01',
  //     type: 1,
  //     date: '15, May 2020',
  //     images: [
  //       // {
  //       //   id: '1',
  //       //   name: '',
  //       //   privateUrl: "/saryah-admin/placePictures/23302a6e-7f82-4a3d-8c0b-11c030dc2d50.svg",
  //       //   publicUrl: 'https://unsplash.it/200?random',
  //       //   sizeInBytes: 31095,
  //       // },
  //       {
  //         id: '1',
  //         name: 'Image 1',
  //         privateUrl: 'https://unsplash.it/200?random',
  //         publicUrl: 'https://unsplash.it/200?random',
  //         // sizeInBytes: ,
  //       }, 
  //       {
  //         id: '2',
  //         name: 'Image 2',
  //         privateUrl: 'https://unsplash.it/200?random',
  //         publicUrl: 'https://unsplash.it/200?random',
  //         // sizeInBytes: ,
  //       }, 
  //       {
  //         id: '3',
  //         name: 'Image 3',
  //         privateUrl: 'https://unsplash.it/200?random',
  //         publicUrl: 'https://unsplash.it/200?random',
  //         // sizeInBytes: ,
  //       },
  //       {
  //         id: '4',
  //         name: 'Image 4',
  //         privateUrl: 'https://unsplash.it/200?random',
  //         publicUrl: 'https://unsplash.it/200?random',
  //         // sizeInBytes: ,
  //       }, 
  //       {
  //         id: '5',
  //         name: 'Image 5',
  //         privateUrl: 'https://unsplash.it/200?random',
  //         publicUrl: 'https://unsplash.it/200?random',
  //         // sizeInBytes: ,
  //       }, 
  //     ],
  //     places: [
  //       'Quba Mosque',
  //       'Al Hejaz Railway Museum',
  //       'Uhud Mountin',
  //       'Mosque',
  //       'Quba Mosque3',
  //       'Al Hejaz Railway Museum2',
  //       'Uhud Mountin2',
  //       'Mosque2',
  //       'Quba Mosque2',
  //       'Al Hejaz Railway Museum3',
  //       'Uhud Mountin3',
  //       'Mosque3',
  //     ]
  //   }
  // },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doUpdate: 'plan/form/doUpdate',
    }),
    // presenter(record, fieldName) {
    //   return PlanModel.presenter(record, fieldName);
    // },
    i18n(code, args) {
      return i18n(code, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      routerAsync().push(`/plan/${this.model.startCityId}`);
    },
    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);

      values.numberOfDays = parseInt(this.model.numberOfDays);
      // values.programs = Object.keys(this.programList).map(key => {
      //   return {
      //     name: key,
      //     places: this.programList[key].map(item => {
      //       return {
      //         id: item['id'],
      //         selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
      //         averageTime: item['averageTime'] ? item['averageTime'] : 0,
      //       }
      //     })
      //   }
      // })
      let programs = []
      this.model.programs.forEach(prog => {

        const places = prog.places.map(place => {
          return {
            id: place['id'],
            selectedImage: place['selectedImage'] ? place['selectedImage'] : '',
            averageTime: place['averageTime'] ? place['averageTime'] : 0,
          }
        })
        programs.push({
          name: prog.name,
          places: places,
        })
        // prog.places.forEach(place => {
        //   delete place.place
        // });
      }); 
      values.programs = programs
      if (this.isEditing) {
        return await this.doUpdate({
          id,
          values,
        });
      } else {
        let record = await this.doCreate(values);
        return record;
      }
    },
  },
};
